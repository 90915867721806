import React from 'react'

import Link from 'next/link'

import { socialIcons } from '@/data/index'

const Social = () => {
  return (
    <div className="flex md:mt-4 justify-between sm:justify-center sm:mt-0">
      <div className="flex items-center">
        {socialIcons.map((item) => {
          const { id, href, text } = item
          return (
            <Link
              href={href}
              key={id}
              target="_blank"
              rel="noopener noreferrer"
              className="group cursor-pointer mx-2"
            >
              <svg
                fill="currentColor"
                className="icon group-hover:fill-accent fill-neutral-100 h-6 w-6 dark:group-hover:fill-white hover:fill-secondary-600"
              >
                <use xlinkHref={`/icons.svg#icon-${text}`} />
              </svg>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default Social
