import React from 'react'

const ShowVersion = () => {
  const version = process.env.version
  return (
    <div className='text-sm text-gray-500 sm:text-right text-left p-2 dark:text-gray-400'>
      Version <span>{version}</span>
    </div>
  )
}

export default ShowVersion
