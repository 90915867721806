import React from 'react'
import Link from 'next/link'

import { Title } from '@/components/common'
import { Slider } from '@/components/slider'
import { IntentionCandles } from '@/data/gallery'

interface IProps {
  id: string
  target: string
}

const Showroom = ({ id, target }: IProps) => {
  return (
    <section
      id={id}
      className="relative h-auto pb-20 lg:-top-1/3 hidden lg:block"
    >
      <div className="container mx-auto">
        <Title text="Nuestras Velas" level={2} className="pb-10 pt-16" />
        <Slider candles={IntentionCandles} />
        <div className="py-4 text-center">
          <Link href={target} className="absolute rounded-lg py-2 text-white">
            <div className="animate-bounce bg-white dark:bg-slate-800 p-2 w-10 h-10 ring-1 ring-slate-900/5 dark:ring-slate-200/20 shadow-lg rounded-full flex items-center justify-center">
              <svg
                className="motion-reduce:animate-bounce text-primary-500 h-6 w-6"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              >
                <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
              </svg>
            </div>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Showroom
