const footerMenuList = [
  {
    id: 1,
    title: 'Legal',
    className: 'md:col-start-7',
    links: [
      {
        id: 1,
        href: '/terminos',
        text: 'Términos y condiciones'
      },
      {
        id: 2,
        href: '/privacidad',
        text: 'Políticas de privacidad'
      }
    ]
  },
  {
    id: 2,
    title: 'Nosotros',
    className: '',
    links: [
      {
        id: 1,
        href: '/contacto',
        text: 'Contacto'
      }
    ]
  }
]

const socialIcons = [
  {
    id: 1,
    href: 'https://instagram.com/candles_aromi',
    text: 'instagram'
  },
  {
    id: 2,
    href: 'https://facebook.com/CandlesAromi',
    text: 'facebook'
  }
]

export { footerMenuList, socialIcons }
