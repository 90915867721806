import React from 'react'
import Link from 'next/link'

interface IProps {
  icon: React.ElementType
  name: string
  content: string
  link: string
}

const ItemContainer = (props: IProps) => {
  const { icon: Icon, name, content, link } = props
  return (
    <div className="relative flex flex-col md:mt-2">
      <button className="group flex items-center gap-x-5 rounded-md px-2.5 py-2 transition-all duration-75 hover:bg-primary-100">
        <div className="flex h-12 w-12 items-center rounded-lg bg-secondary-200 text-black group-hover:bg-neutral-200">
          <span className="tag w-full text-center text-lg items-center font-medium text-gray-700 group-hover:text-secondary-600">
            <Icon className="mx-auto h6- w-6" />
          </span>
        </div>
        <div className="flex flex-col items-start justify-between font-light text-gray-600 ">
          <p className="text-primary-800 font-bold text-lg">{name}</p>
          <span className="md:absolute md:right-0 md:px-4 max-w-[235px] md:max-w-[550px] text-left md:text-right">
            <Link href={link}>{content}</Link>
          </span>
        </div>
      </button>
    </div>
  )
}

export default ItemContainer
