import React from 'react'
import Link from 'next/link'
import Image from 'next/image'

const ImageFooter = () => {
  return (
    <div className="items-center self-center">
      {/* <!-- Logo --> */}
      <Link href="#" className="mb-6">
        <Image
          src="/aromi_logo.png"
          width={135}
          height={90}
          className="mt-2 dark:hidden mx-auto"
          alt=""
        />
      </Link>

      <Link href="#" className="mb-6">
        <Image
          src="/logo_white.png"
          width={90}
          height={60}
          className="hidden h-20 dark:block mt-2 mx-auto"
          alt=""
        />
      </Link>
    </div>
  )
}

export default ImageFooter
