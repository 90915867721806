import React from 'react'
import Link from 'next/link'

import { ShowVersion } from '@/components/about'
import Config from '@/data/site'
import ImageFooter from './ImageFooter'
import MenuSection from './MenuSection'
import { footerMenuList } from '@/data/index'
import Social from './Social'

const Footer = () => {
  return (
    <footer className="relative w-screen bg-primary-300 shadow dark:bg-gray-900">
      <div className="w-full grid md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-14 mb-4 md:py-4">
        <ImageFooter />
        {footerMenuList.map((section) => {
          return <MenuSection key={section.id} section={section} />
        })}
      </div>

      <hr className=" border-gray-200 sm:mx-auto dark:border-gray-700" />

      <div className="w-full grid md:grid-cols-3 md:gap-14 px-4 py-2 place-items-center">
        <div className="sm:flex sm:items-center sm:justify-between py-2">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400 ">
            <span className="group-hover:fill-accent dark:hover:text-white">
              <Link href={Config.site.url} className="dark:hover:text-white">
                © {new Date().getFullYear()} {Config.site.name}. All Rights
                Reserved
              </Link>
            </span>
          </span>
        </div>
        <Social />
        <ShowVersion />
      </div>
    </footer>
  )
}

export default Footer
