import React from 'react'
import { motion } from 'framer-motion'

import { ICandlePreview } from '@/components/common'

interface IProps {
  candles: ICandlePreview[]
  activeImage: number
}

const Description = (props: IProps) => {
  const { candles, activeImage } = props

  return (
    <div className="grid w-full h-full bg-secondary-100 md:rounded-tr-3xl md:rounded-br-3xl">
      {candles.map((elem, idx) => (
        <div
          key={idx}
          className={`${
            idx === activeImage
              ? 'block w-full h-full md:px-20 text-left'
              : 'hidden'
          }`}
        >
          <motion.div
            initial={{
              opacity: idx === activeImage ? 0 : 0.5,
              scale: idx === activeImage ? 0.5 : 0.3
            }}
            animate={{
              opacity: idx === activeImage ? 1 : 0.5,
              scale: idx === activeImage ? 1 : 0.3
            }}
            transition={{
              ease: 'linear',
              duration: 2,
              x: { duration: 1 }
            }}
          >
            <div className="py-16 text-5xl font-extrabold">{elem.title}</div>
            <div className="leading-relaxed font-medium text-base tracking-wide h-60 md:h-40 italic text-gray-600">
              {' '}
              {elem.benefits}
            </div>
          </motion.div>

          <button className="bg-primary-400 hidden text-white uppercase px-4 py-2 rounded-md">
            Ver detalles
          </button>
        </div>
      ))}
    </div>
  )
}

export default Description
