import React from 'react'

import Link from 'next/link'

import { MenuSection as Section } from './types'

interface IProps {
  section: Section
}

const MenuSection = ({ section }: IProps) => {
  const { id, title, links } = section
  return (
    <div key={id} className="w-full px-2">
      <h3 className="font-display uppercase text-secondary-700 my-2 md:mb-6 md:mt-4 text-lg dark:text-white ">
        {title}
      </h3>
      <ul className="dark:text-primary-300 flex flex-col space-y-1 px-8 md:px-2">
        {links.map((item) => {
          const { id, href, text } = item
          return (
            <li key={id}>
              <Link
                href={href}
                className="group-hover:fill-accent dark:hover:text-white text-lg w-full"
              >
                {text}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default MenuSection
