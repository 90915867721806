import React from 'react'
import Image from 'next/image'

import Form from './Form'

interface IProps {
  imagen: string
}

const Contact = (props: IProps) => {
  const { imagen } = props
  return (
    <section id="contacto" className="py-8 px-4 bg-neutral-100 min-h-[45rem]">
      <div className="grid grid-cols-1 md:grid-cols-2 auto-cols-max">
        <div className="py-10">
          <Image
            width={460}
            height={690}
            src={imagen}
            className="col-span-1 w-full mx-auto rounded-xl lg:w-10/12"
            alt="Psicóloga Infantil - Contacto"
            title="Psicóloga Infantil - Contacto"
          />
        </div>

        <div className="container">
          <p className="py-8 text-xl font-sans w-full text-center">
            Las velas aromáticas Tienen distintos beneficios en tu vida.
          </p>
          <p className="py-4 text-xl font-sans w-full text-center uppercase text-secondary-500">
            Velas artesanales, con ingredientes naturales para consentirte a ti
            y al planeta
          </p>
          <h2 className="text-3xl font-bold my-10 w-full text-center">
            Puedes ponerte en contacto con nosotros mediante el siguiente
            formulario.
          </h2>
          <Form />
        </div>
      </div>
    </section>
  )
}

export default Contact
