'use client'
import React from 'react'

import { Welcome, Showroom, Benefits } from '@/components/home'
import { Footer } from '@/components/footer'
import { PageWrapper } from '@/components/common'
import { Whatsapp } from '@/components/whatsapp'
import { useIsMobile } from '@/lib/hooks'
import { ContactForm } from '@/components/contact'

export default function Home() {
  const isMobile = useIsMobile(500)

  const welcomeTarget = isMobile ? '/#benefits' : '/#showroom'
  return (
    <>
      <section className="top-0 h-screen w-full bg-neutral-100">
        <PageWrapper>
          <Welcome id="welcome" target={welcomeTarget} />
          <Showroom id="showroom" target={'#benefits'} />
        </PageWrapper>
        <Benefits id="benefits" />
        <ContactForm imagen="/images/bg_about.webp" />
        <Footer />
      </section>
      <Whatsapp />
    </>
  )
}
