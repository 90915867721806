'use client'
import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import { ICandlePreview } from '@/components/common'

interface IProps {
  activeImage: number
  direction: number
  candles: ICandlePreview[]
}

export const ImageContainer = (props: IProps) => {
  const { activeImage, candles, direction } = props

  const images = {
    hidden: {
      opacity: 0,
      x: 30
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1
      }
    }
  }
  return (
    <div className="flex max-h-[60vh]">
      <AnimatePresence initial={false} custom={direction}>
        {candles.map((image, idx) => (
          <motion.img
            key={idx}
            src={image.src}
            title={image.title}
            custom={direction}
            variants={images}
            initial={{
              opacity: idx === activeImage ? 0 : 0.5,
              scale: idx === activeImage ? 0.5 : 0.3
            }}
            animate={{
              opacity: idx === activeImage ? 1 : 0.5,
              scale: idx === activeImage ? 1 : 0.3
            }}
            transition={{
              ease: 'linear',
              duration: 2,
              x: { duration: 1 }
            }}
            className={`${
              idx === activeImage
                ? 'block w-full max-h-[60vh] object-cover rounded-tl-3xl rounded-bl-3xl -z-30'
                : 'hidden'
            }`}
          />
        ))}
      </AnimatePresence>
    </div>
  )
}
