import React, { useCallback, useEffect, useState } from 'react'

import { type ICandlePreview } from '@/components/common'
import Description from './Description'
import { ImageContainer } from './ImageContainer'

interface IProps {
  candles: ICandlePreview[]
}

const Slider = ({ candles }: IProps) => {
  const [[activeImage, direction], setActiveImage] = useState([0, 0])

  const totalCandles = candles.length - 1
  const nextImage = activeImage + 1
  const prevImage = activeImage - 1

  const isLatest = activeImage === totalCandles
  const isFirst = activeImage === 0

  const next = useCallback(() => {
    if (isLatest) {
      setActiveImage([0, 0])
    } else {
      setActiveImage([nextImage, 1])
    }
  }, [isLatest, nextImage])

  const previous = () => {
    if (isFirst) {
      setActiveImage([totalCandles, -1])
    } else {
      setActiveImage([prevImage, -1])
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      next()
    }, 5000)
    return () => {
      clearTimeout(timer)
    }
  }, [activeImage, direction, next])

  return (
    <div className="grid place-items-center grid-cols-1 md:grid-cols-2 w-full mx-auto max-w-5xl shadow-3xl rounded-3xl">
      <div className="relative w-full justify-center items-center gap-0  md:rounded-2xl p-6 md:p-0">
        <ImageContainer
          candles={candles}
          direction={direction}
          activeImage={activeImage}
        />
        <div className="absolute md:bottom-1 -top-12 bottom-10 right-10 md:right-0 w-full  justify-center align-middle items-center">
          <div className="next" onClick={() => next()}>
            {'‣'}
          </div>

          <div className="prev" onClick={() => previous()}>
            {'‣'}
          </div>
        </div>
      </div>
      <Description candles={candles} activeImage={activeImage} />
    </div>
  )
}

export default Slider
