'use client'
import React from 'react'
import Image from 'next/image'

import { Title } from '@/components/common'
import Link from 'next/link'

interface IProps {
  id: string
  target: string
}

const Welcome = ({ id, target }: IProps) => {
  return (
    <>
      <section
        id={id}
        className="flex flex-col lg:flex-row h-[60xh] mb-10 md:h-screen container pt-20 "
      >
        <div className="w-full lg:w-3/6 ">
          <Title
            level={1}
            text="Velas artesanales | Calidez para tu hogar"
            className="py-2 md:py-5"
          />
          <div className="relative lg:hidden text-neutral-600 text-lg my-4 lg:my-10 font-medium w-full pr-4 text-end">
            Descubre nuestra selección de velas artesanales, creadas con
            ingredientes naturales para llevar la calidez y la tranquilidad a tu
            hogar.
          </div>
          <div className="flex w-full py-8 place-content-center">
            <Link
              href={target}
              className="mx-4 bg-secondary-400 text-white  py-2 px-12 rounded-lg text-center cursor-pointer hover:bg-primary-500 delay-[100ms] duration-[400ms] uppercase"
            >
              {'Saber más'}
            </Link>
          </div>
        </div>
        <div className="lg:w-2/4">
          <Image
            src="/images/disipador.jpg"
            width={420}
            height={800}
            alt="Candles Aromi"
            title="Candles Aromi"
            className="rounded-lg"
          />
        </div>
      </section>
      <div className="absolute hidden lg:block h-1/4 md:top-1/3 xl:top-2/4 text-neutral-600 text-xl my-10 p-4 font-semibold w-full lg:w-2/5 pr-4 lg:ml-20 text-end pt-72 lg:pt-2">
        Descubre nuestra selección de velas artesanales, creadas con
        ingredientes naturales para llevar la calidez y la tranquilidad a tu
        hogar.
      </div>
    </>
  )
}

export default Welcome
