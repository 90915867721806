'use client'
import * as React from 'react'
import { useState } from 'react'
import { useMediaQuery } from 'usehooks-ts'
import { sendGAEvent, sendGTMEvent } from '@next/third-parties/google'

import useHasScrolled from '@/lib/hooks'
import { ChatBox } from './sections'
import builtWALink from './sections/code'
import Config from '@/data/site'

const Whatsapp = () => {
  const isScrolled = useHasScrolled(20)
  const [display, setDisplay] = useState(false)
  const matches = useMediaQuery('(min-width: 768px)')
  const { trackAW, trackGTM, conversionID } = Config.google
  const show = isScrolled ? '' : 'whats-btn-show'

  const handleDisplaying = async () => {
    if (matches) {
      // console.log('handle')
      setDisplay(!display)
    } else {
      // console.log('buildWA')
      const link = await builtWALink()

      Promise.all([
        sendGAEvent({
          event: 'whatsappMsg',
          value: { send_to: `${trackAW}/${conversionID}` }
        }),
        sendGTMEvent({
          event: 'whatsappMsg',
          value: { send_to: `${trackGTM}/${conversionID}` }
        })
      ])
        .then(() => {
          // Redirigir a un sitio externo
          window.open(link, '_blank', 'noopener,noreferrer')
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error sending events:', error)
          // Opción para manejar el error o redirigir de todos modos
        })
    }
  }

  return (
    <>
      <button
        className={`whats-btn transition duration-150 ease-in-out ${show}`}
        type="button"
        role="button"
        rel="noreferrer"
        data-modal-target="static-modal"
        data-modal-toggle="static-modal"
        onClick={handleDisplaying}
      >
        <span className="text-transparent">Text</span>
      </button>

      {display ? <ChatBox id="chat" onClick={handleDisplaying} /> : ''}
    </>
  )
}

export default Whatsapp
