import React from 'react'

import { CandleList } from '@/components/common'
import { Intro } from '@/data/home/benefits'
import { Title } from '@/components/common'

interface IProps {
  id: string
}

const Benefits = ({ id }: IProps) => {
  return (
    <section
      id={id}
      className="grid grid-flow-row items-center justify-center bg-fixed bg-parallax bg-no-repeat bg-center bg-cover h-auto py-20"
    >
      <div className="w-full place-items-center text-center my-6">
        <Title
          text={Intro.title}
          level={2}
          className="pb-2 pt-0 w-5/6 lg:w-2/3 mx-auto bg-primary-100 bg-opacity-90 rounded"
        />
      </div>
      <CandleList />
    </section>
  )
}

export default Benefits
