import { EnvelopeIcon, PhoneArrowUpRightIcon } from '@heroicons/react/24/solid'
import Config from '@/data/site'

export const Title = 'Información de Contacto'

export const Description =
  'Si desea más información sobre nuestros servicios, cuenta con las siguientes opciones:'

const { contact } = Config

export const Details = [
  {
    icon: PhoneArrowUpRightIcon,
    name: 'Teléfono',
    content: contact.phonemask,
    link: contact.phonelink
  },
  {
    icon: EnvelopeIcon,
    name: 'Correo Electrónico',
    content: atob(contact.email),
    link: `mailto:${atob(contact.email)}`
  }
]
